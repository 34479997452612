@import '~normalize.css/normalize',
  'variables';

/*
  Notes regarding fonts:
  - TTF files generally work in all browsers but some didn't work in Chrome. Generate woff/woff2 equivalents for any new fonts here: https://transfonter.org/
  - Fonts do not seem to work if there are any numbers in the font-family name in the @font-face directive.
  - Fonts will render in browser's correctly as long as the CSS font family matches the font-family in the @font-face directive. Source file name and internal family name are irrelevant.
  - Fonts will render server side as long as the CSS font family matches the source file's internal family name. Source file name is irrelevant.
  - Use FontForge to change the source file's internal family name if needed. http://fontforge.github.io/en-US/

  Important:
  - Add any new fonts to the marketing site template.
  - Make sure fonts render on both dev and prod. (E.g. when creating a postcard rendering.)
 */

@font-face {
  font-family: 'A Hundred Miles';
  src:
    local('☺'),
    url('../assets/fonts/A Hundred Miles.woff2') format('woff2'),
    url('../assets/fonts/A Hundred Miles.woff') format('woff'),
    url('../assets/fonts/A Hundred Miles.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src:
    local('☺'),
    url('../assets/fonts/AvenirNext-Medium.woff') format('woff'),
    url('../assets/fonts/AvenirNext-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CTorres';
  src:
    local('☺'),
    url('../assets/fonts/CTorres.woff2') format('woff2'),
    url('../assets/fonts/CTorres.woff') format('woff'),
    url('../assets/fonts/CTorres.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FG Bonnies Font';
  src:
    local('☺'),
    url('../assets/fonts/FG Bonnies Font.woff2') format('woff2'),
    url('../assets/fonts/FG Bonnies Font.woff') format('woff'),
    url('../assets/fonts/FG Bonnies Font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Givens';
  src:
    local('☺'),
    url('../assets/fonts/Givens.woff2') format('woff2'),
    url('../assets/fonts/Givens.woff') format('woff'),
    url('../assets/fonts/Givens.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src:
    url('../assets/fonts/helveticaneueltstd-bd-webfont.woff2') format('woff2'),
    url('../assets/fonts/helveticaneueltstd-bd-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src:
    url('../assets/fonts/helveticaneueltstd-md-webfont.woff2') format('woff2'),
    url('../assets/fonts/helveticaneueltstd-md-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src:
    url('../assets/fonts/helveticaneueltstd-roman-webfont.woff2') format('woff2'),
    url('../assets/fonts/helveticaneueltstd-roman-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    url('../assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype'),
    url('../assets/fonts/Inter-Black.ttf') format('truetype'),
    url('../assets/fonts/Inter-Bold.ttf') format('truetype'),
    url('../assets/fonts/Inter-ExtraBold.ttf') format('truetype'),
    url('../assets/fonts/Inter-ExtraLight.ttf') format('truetype'),
    url('../assets/fonts/Inter-Light.ttf') format('truetype'),
    url('../assets/fonts/Inter-Medium.ttf') format('truetype'),
    url('../assets/fonts/Inter-SemiBold.ttf') format('truetype'),
    url('../assets/fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Levy';
  src:
    local('☺'),
    url('../assets/fonts/Levy.woff2') format('woff2'),
    url('../assets/fonts/Levy.woff') format('woff'),
    url('../assets/fonts/Levy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lindy';
  src:
    local('☺'),
    url('../assets/fonts/Lindy.woff2') format('woff2'),
    url('../assets/fonts/Lindy.woff') format('woff'),
    url('../assets/fonts/Lindy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lindy Bold';
  src:
    local('☺'),
    url('../assets/fonts/Lindy-Bold.woff2') format('woff2'),
    url('../assets/fonts/Lindy-Bold.woff') format('woff'),
    url('../assets/fonts/Lindy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Michele Hand';
  src:
    local('☺'),
    url('../assets/fonts/Michele Hand.woff2') format('woff2'),
    url('../assets/fonts/Michele Hand.woff') format('woff'),
    url('../assets/fonts/Michele Hand.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SignPainter';
  src:
    local('☺'),
    url('../assets/fonts/SignPainter-HouseScript.woff2') format('woff2'),
    url('../assets/fonts/SignPainter-HouseScript.woff') format('woff'),
    url('../assets/fonts/SignPainter-HouseScript.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'You Wont Bring Me Down';
  src:
    local('☺'),
    url('../assets/fonts/You Wont Bring Me Down.woff2') format('woff2'),
    url('../assets/fonts/You Wont Bring Me Down.woff') format('woff'),
    url('../assets/fonts/You Wont Bring Me Down.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Your Font';
  src:
    local('☺'),
    url('../assets/fonts/Your Font.woff2') format('woff2'),
    url('../assets/fonts/Your Font.woff') format('woff'),
    url('../assets/fonts/Your Font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  a {
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  a[role="button"] {
    &:active,
    &:focus,
    &:hover {
      text-decoration: inherit;
    }
  }
}

:global {
  .bodyModal {
    overflow: hidden;
  }
}

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: $font-helvetica-neue;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

svg {
  fill: #66707d;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

input,
optgroup,
button,
textarea,
select,
a {
  outline: none;
  user-select: none;
  font-family: $font-helvetica-neue;
}

input::-ms-clear {
  display: none;
}

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

:global {
  .react-datepicker {
    height: 270px;

    &__tether-element {
      height: 270px;
    }
  }
}

.fullHeight {
  height: 100vh;
}

.flex {
  display: flex;
}

.vFlex {
  display: flex;
  flex-flow: column;
}

.vrFlex {
  display: flex;
  flex-flow: column-reverse;
}

.rFlex {
  display: flex;
  flex-flow: row-reverse;
}


:global {
  .clsFillterDatePicker {
    .react-datepicker-wrapper {
      display: block;
    }
    .react-datepicker-ignore-onclickoutside{ 
      box-shadow: 0px 0px 0px 2px #128292 inset; 
    }

    .react-datepicker-popper {
      z-index: 999;

      &[data-placement^="top"] {
        margin-bottom: 5px;
      }

      &[data-placement^="bottom"] {
        margin-top: 5px;
      }
    }

    .react-datepicker__month-container {
      float: none;
      border: solid 2px #128292;
      border-radius: 12px;
      min-width: 200px;
      position: relative;
      z-index: 999;
      background: #fff;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
    }

    .react-datepicker__header {
      background-color: #fff;
      padding: 0;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border: 0;
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__day-names {
      padding: 0 10px 5px;
    }

    .react-datepicker__week {
      padding: 0 10px 5px;

      &:last-child {
        padding-bottom: 10px;
      }
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: #3C4654;
      font-size: 14px;
      font-weight: 600;
      height: 30px;
      width: 30px;
      margin: 0;
      line-height: 0;
      margin-right: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:last-child {
        margin: 0;
      }
    }

    .react-datepicker__day--keyboard-selected {
      border-radius: 999px;
      background-color: #128292;
      color: #fff;
      font-weight: 600;
      &:hover{
        background-color: #128292 !important;
        color: #fff;
      }
    }

    .react-datepicker__day:hover {
      border-radius: 999px;
      background-color: #DFE1E4;
    }

    .react-datepicker__day:focus {
      border-radius: 999px;
      background-color: #DFE1E4;
    }
  }
}

:global {
  .ag-theme-balham .ag-root-wrapper{
    border: 0 !important; 
  }
  .ag-theme-balham .ag-root {
    border: 1px solid #bdc3c7;
  }

  .clsSearchDateTo{
    display: block;
    .react-datepicker-popper{
      right: 0;
      left: auto !important
    }
    .react-datepicker__triangle{
      left: auto;
      right: 50px;
    }
  }
}