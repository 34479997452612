@import '~app/mixins';

.bulletPoint {
  display: flex;

  div:first-child {
    font-weight: bold;
    font-size: 20px;
    display: inline-block;
    width: 8px;
    margin: 1px 6px 0 0;
  }

  div:last-child {
    flex: 1;
  }
}
